<template>
	<Fragment>
		<div class="section">
			<h3 class="heading-3-b mb-20">회사 법인 정보</h3>
			<div class="contents group column gap20">
				<div class="group row col2 gap40 align-start">
					<!-- 상호명 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.corporateName == 'valid' || companyInfoValidClass.corporateName == ''
								? 'valid'
								: 'invalid',
						]"
					>
						<label class="label asterisk"
							>상호명
							<i class="ic-16 ic-info">
								<span class="tooltip-layer left"> 사업자등록증에 표기되어 있는 상호명을 입력해주세요. </span>
							</i>
						</label>
						<input
							class=""
							type="text"
							id="corporateName"
							placeholder="상호명을 입력해주세요"
							:disabled="companyInfoDisabled.corporateName"
							v-model="companyInfo.corporateName"
							@input="[validSpecialInput($event)]"
						/>
						<p
							v-if="companyInfoValidClass.corporateName != 'valid' && companyInfoValidClass.corporateName.length > 0"
							class="text-detail invalid"
						>
							<i class="ic-16 ic-invalid"></i>
							{{
								companyInfoValidClass.corporateName == 'invalid'
									? '상호명에 | \\ " # $ &lt; &gt; , ; 특수문자는 사용 불가합니다.'
									: companyInfoValidClass.corporateName == 'error'
									? '상호명은 2자 이상, 64자 이하를 입력해야 합니다.'
									: '상호명은 필수 입력 항목입니다.'
							}}
						</p>
					</div>
					<!-- 대표자 명 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.ceoName == 'error' || companyInfoValidClass.ceoName == 'empty'
								? 'invalid'
								: companyInfoValidClass.ceoName == 'valid'
								? 'valid'
								: '',
						]"
					>
						<label class="label asterisk">대표자명</label>
						<input
							class="asterisk"
							type="text"
							id="ceoName"
							placeholder="대표자명을 입력해주세요"
							:disabled="companyInfoDisabled.ceoName"
							v-model="companyInfo.ceoName"
							@input="
								[
									checkInputLength($event, 2, 100)
										? (companyInfoValidClass.ceoName = 'valid')
										: (companyInfoValidClass.ceoName = 'error'),
								]
							"
						/>
						<p v-if="companyInfoValidClass.ceoName == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							대표자명은 2자 이상, 100자 이하를 입력해야 합니다.
						</p>
						<p v-if="companyInfoValidClass.ceoName == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							대표자명은 필수 입력 항목입니다.
						</p>
					</div>
				</div>
				<div class="group row col1 half">
					<!-- 사업자 등록번호 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.businessLicenseNum == 'error' || companyInfoValidClass.businessLicenseNum == 'empty'
								? 'invalid'
								: companyInfoValidClass.businessLicenseNum == 'valid'
								? 'valid'
								: '',
							isBusinessActive ? 'active' : '',
						]"
					>
						<label class="label asterisk"
							>사업자등록번호
							<i class="ic-16 ic-info">
								<span class="tooltip-layer left">
									사업자등록번호, 개업일자, 대표자성명은 사업자 진위 확인 필수값입니다. 반드시 입력 후 진위 확인을
									하세요.
								</span>
							</i>
						</label>
						{{ companyInfo.businessNum }}
						<!--						<div class="group-inp-btn" :class="{ invalid: !businessInfoConfirm }">-->
						<div class="group-inp-btn" :class="{ invalid: businessInfoConfirm == false }">
							<input
								class="asterisk"
								type="text"
								id="businessLicenseNum"
								placeholder="사업자등록번호를 입력해주세요"
								:disabled="companyInfoDisabled.businessLicenseNum"
								v-model.trim="companyInfo.businessLicenseNum"
								v-doms-inputValidate:onlyNumber=""
								@keydown="changeBussinessInfo"
								maxlength="10"
							/>
							<div
								v-if="!companyInfoDisabled.businessLicenseNum"
								class=""
								@mouseover="isBusinessActive = true"
								@mouseleave="isBusinessActive = false"
							>
								<LicenseConfirm
									v-model="businessInfoConfirm"
									:businessLicenseNumber="companyInfo.businessLicenseNum"
								></LicenseConfirm>
							</div>
						</div>
						<p v-if="companyInfoValidClass.businessLicenseNum == 'valid'" class="text-detail valid">
							<i class="ic-16 ic-valid"></i>확인되었습니다.
						</p>
						<p v-if="companyInfoValidClass.businessLicenseNum == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							번호 형식이 올바르지 않습니다. (-, +, 0~9)
						</p>
						<p v-if="companyInfoValidClass.businessLicenseNum == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							사업자등록번호는 필수 입력 항목입니다.
						</p>
					</div>
				</div>
				<div class="group row col2 gap40 align-start">
					<!-- 업태 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.businessCondition == 'error' || companyInfoValidClass.businessCondition == 'empty'
								? 'invalid'
								: companyInfoValidClass.businessCondition == 'valid'
								? 'valid'
								: '',
						]"
					>
						<label class="label asterisk">업태</label>
						<input
							class="asterisk"
							type="text"
							id="businessCondition"
							placeholder="업태를 입력해주세요"
							:disabled="companyInfoDisabled.businessCondition"
							v-model="companyInfo.businessCondition"
							@input="
								[
									checkInputLength($event, 2, 64)
										? (companyInfoValidClass.businessCondition = 'valid')
										: (companyInfoValidClass.businessCondition = 'error'),
								]
							"
						/>

						<p v-if="companyInfoValidClass.businessCondition == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							업태는 2자 이상, 64자 이하를 입력해야 합니다.
						</p>
						<p v-if="companyInfoValidClass.businessCondition == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							업태는 필수입력 항목입니다.
						</p>
					</div>
					<!-- 종목 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.businessType == 'error' || companyInfoValidClass.businessType == 'empty'
								? 'invalid'
								: companyInfoValidClass.businessType == 'valid'
								? 'valid'
								: '',
						]"
					>
						<label class="label asterisk">종목</label>
						<input
							class="asterisk"
							type="text"
							id="businessType"
							placeholder="종목을 입력해주세요"
							:disabled="companyInfoDisabled.businessType"
							v-model="companyInfo.businessType"
							@input="
								[
									checkInputLength($event, 2, 64)
										? (companyInfoValidClass.businessType = 'valid')
										: (companyInfoValidClass.businessType = 'error'),
								]
							"
						/>
						<p v-if="companyInfoValidClass.businessType == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							종목은 2자 이상, 64자 이하를 입력해야 합니다.
						</p>
						<p v-if="companyInfoValidClass.businessType == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							종목은 필수입력 항목입니다.
						</p>
					</div>
				</div>
				<div class="group row col2 gap40 align-start">
					<!-- 사업장 주소 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.companyAddress == 'error' || companyInfoValidClass.companyAddress == 'empty'
								? 'invalid'
								: companyInfoValidClass.companyAddress == 'valid'
								? 'valid'
								: '',
						]"
					>
						<label class="label asterisk">사업장 주소</label>
						<input
							class="asterisk"
							type="text"
							id="companyAddress"
							placeholder="사업장 주소를 입력해주세요"
							:disabled="companyInfoDisabled.companyAddress"
							v-model="companyInfo.companyAddress"
							@input="
								[
									checkInputLength($event, 0, 5000)
										? (companyInfoValidClass.companyAddress = 'valid')
										: (companyInfoValidClass.companyAddress = 'error'),
								]
							"
						/>
						<p
							v-if="companyInfoValidClass.companyAddress == 'error' || companyInfoValidClass.companyAddress == 'empty'"
							class="text-detail invalid"
						>
							<i class="ic-16 ic-invalid"></i>
							사업장 주소는 필수입력 항목입니다.
						</p>
					</div>

					<!-- 임직원 수 -->
					<div
						class="group-inp"
						:class="[
							companyInfoValidClass.employeeCount == 'error' || companyInfoValidClass.employeeCount == 'empty'
								? 'invalid'
								: companyInfoValidClass.employeeCount == 'valid'
								? 'valid'
								: '',
						]"
					>
						<label class="label asterisk">실 임직원 수</label>
						<input
							class=""
							min="0"
							id="employeeCount"
							v-model="companyInfo.employeeCount"
							type="text"
							placeholder="실 임직원 수를 입력해주세요"
							:disabled="companyInfoDisabled.employeeCount"
							v-doms-inputValidate:onlyNumber=""
							@keyup="
								[
									!companyInfo.employeeCount || companyInfo.employeeCount < 1 || companyInfo.employeeCount > 99999
										? (companyInfoValidClass.employeeCount = 'error')
										: (companyInfoValidClass.employeeCount = 'valid'),
								]
							"
						/>
						<p v-if="companyInfoValidClass.employeeCount == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							임직원 수는 1 이상, 99999 이하를 입력해야 합니다.
						</p>
						<p v-if="companyInfoValidClass.employeeCount == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							임직원 수는 필수 입력 항목입니다.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<h3 class="heading-3-b mb-20">사업자등록증</h3>
			<AttachComponent
				:gatherFlag="gatherFlag"
				:isEdit="companyInfo.businessLicenses.length > 0 ? false : true"
				:useLabel="true"
				attach-label-text="사업자등록증"
				attach-btn-name="파일 첨부"
			></AttachComponent>
		</div>
	</Fragment>
</template>

<script>
import _ from 'lodash';
import { validation } from '@/components/apply/mixinsValidation.js';
import AttachComponent from '@/components/attach/Attach';
import LicenseConfirm from '@/components/businessLicense/LicenseConfirm';

export default {
	mixins: [validation],
	components: {
		AttachComponent,
		LicenseConfirm,
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			companyInfo: {
				corporateName: '',
				ceoName: '',
				businessLicenseNum: '',
				businessCondition: '',
				businessType: '',
				companyAddress: '',
				employeeCount: '',
				businessLicenses: [],
			},
			companyInfoDisabled: {
				corporateName: false,
				ceoName: false,
				businessLicenseNum: false,
				businessCondition: false,
				businessType: false,
				companyAddress: false,
				employeeCount: false,
				businessLicenses: false,
			},
			companyInfoValidClass: {},
			businessInfoConfirm: null,
			isBusinessActive: false,
		};
	},

	created() {
		const stateCompanyInfo = this.$store.getters.getCompanyInfo;
		const stateOrderTemp = this.$store.getters.getOrderTempCompanyInfo;
		let getCompanyInfo = {};
		getCompanyInfo = Object.hasOwn(stateCompanyInfo, 'corporateName') ? stateCompanyInfo : stateOrderTemp;

		this.companyInfo.corporateName = getCompanyInfo.corporateName || '';
		this.companyInfo.ceoName = getCompanyInfo.ceoName || '';
		this.companyInfo.businessLicenseNum = getCompanyInfo.businessLicenseNum || '';
		this.companyInfo.businessCondition = getCompanyInfo.businessCondition || '';
		this.companyInfo.businessType = getCompanyInfo.businessType || '';
		this.companyInfo.companyAddress = getCompanyInfo.companyAddress || '';
		this.companyInfo.employeeCount = getCompanyInfo.employeeCount || '';
		this.companyInfo.businessLicenses = getCompanyInfo.businessLicenses || [];

		this.companyInfoValidClass = {
			businessLicenseNum: getCompanyInfo.businessLicenseNum ? 'valid' : '',
			corporateName: getCompanyInfo.corporateName ? 'valid' : '',
			ceoName: getCompanyInfo.ceoName ? 'valid' : '',
			directPhone: 'valid',
			industrySector: 'valid',
			companyZipCode: 'valid',
			businessCondition: getCompanyInfo.businessCondition ? 'valid' : '',
			businessType: getCompanyInfo.businessType ? 'valid' : '',
			companyAddress: getCompanyInfo.companyAddress ? 'valid' : '',
			employeeCount: getCompanyInfo.employeeCount ? 'valid' : '',
		};

		this.businessInfoConfirm = getCompanyInfo.businessLicenseNum ? true : null;
		this.isBusinessActive = false;

		if (!_.isEmpty(getCompanyInfo) && getCompanyInfo !== stateOrderTemp) {
			this.companyInfo.confirm = getCompanyInfo.businessLicenseNum ? true : false;
			this.companyInfoDisabled = {
				corporateName: getCompanyInfo.corporateName ? true : false,
				ceoName: getCompanyInfo.ceoName ? true : false,
				businessLicenseNum: getCompanyInfo.businessLicenseNum ? true : false,
				businessCondition: getCompanyInfo.businessCondition ? true : false,
				businessType: getCompanyInfo.businessType ? true : false,
				companyAddress: getCompanyInfo.companyAddress ? true : false,
				employeeCount: getCompanyInfo.employeeCount ? true : false,
				businessLicenses: this.businessInfoConfirm,
			};
		}
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
		businessInfoConfirm() {
			this.companyInfo.confirm = this.businessInfoConfirm;
			if (this.businessInfoConfirm) {
				this.companyInfoValidClass.businessLicenseNum = 'valid';
			} else {
				this.companyInfoValidClass.businessLicenseNum = '';
			}
		},
		'companyInfo.employeeCount'() {
			if (isNaN(this.companyInfo.employeeCount)) {
				this.companyInfo.employeeCount = '';
			}

			if (
				Number.parseInt(this.companyInfo.employeeCount) < 1 ||
				Number.parseInt(this.companyInfo.employeeCount) > 99999
			) {
				this.companyInfo.employeeCount = '';
			}
		},
	},
	methods: {
		validSpecialInput(event) {
			if (this.checkSpecialChar(event)) {
				this.companyInfoValidClass.corporateName = 'invalid';
			} else if (event.target.value.trim().length == 0) {
				this.companyInfoValidClass.corporateName = 'empty';
			} else if (!this.checkInputLength(event, 2, 64)) {
				this.companyInfoValidClass.corporateName = 'error';
			} else {
				this.companyInfoValidClass.corporateName = 'valid';
			}
		},
		changeBussinessInfo() {
			this.businessInfoConfirm = false;
			this.companyInfo.confirm = false;
			this.companyInfoValidClass.businessLicenseNum = '';
		},
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];
				if (
					key == 'corporateName' ||
					key == 'businessLicenseNum' ||
					key == 'ceoName' ||
					key == 'companyAddress' ||
					key == 'businessCondition' ||
					key == 'businessType' ||
					key == 'employeeCount'
				) {
					if (!value || value.length == 0) {
						this.companyInfoValidClass[key] = 'empty';
					}
					if (this.companyInfoValidClass[key] !== 'valid') {
						isAllValid = false;
					}
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyInfo',
				companyInfo: this.companyInfo,
				companyInfoValidClass: this.companyInfoValidClass,
			});
		},
	},
	computed: {
		customer() {
			return this.$store.getters.getCustomer;
		},
	},
	async beforeDestroy() {
		this.companyInfo.businessLicenses = await this.$store.getters.getBusinessRegistration;
		this.$store.commit('SET_ORDER_TEMP_COMPANY_INFO', this.companyInfo);
	},
};
</script>
