<template>
	<div class="section">
		<h3 class="heading-3-b mb-20">담당자 정보</h3>
		<div class="contents group column gap20">
			<div class="group row col1">
				<div class="group-chk" v-if="!isSuperAdmin">
					<input
						id="isCorporateRepresentativeCheck"
						type="checkbox"
						:checked="this.corporateRepresentativeCheck"
						@click="clickCheckManagerAgreement"
					/>
					<label
						class="label text-detail"
						:class="{ invalid: companyManagerInfoValidClass.isCorporateRepresentativeCheck != 'valid' }"
						@click="clickCheckManagerAgreement"
						>[필수] 다우오피스 신규 주문시 약관 동의를 통해 다우기술과 서비스 이용 계약을 체결한 법인의 대표(혹은 법인
						대리)인 관리자로 전환됩니다.
					</label>
				</div>
			</div>
			<p v-if="companyManagerInfoValidClass.isCorporateRepresentativeCheck === 'empty'" class="text-detail invalid">
				<i class="ic-16 ic-invalid"></i>
				약관 동의는 필수입니다.
			</p>

			<!-- 담당자명 & 이메일  -->
			<div class="group row col2 gap40 align-start">
				<!-- 담당자 명 -->
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.name == 'error' || companyManagerInfoValidClass.name == 'empty'
							? 'error'
							: companyManagerInfoValidClass.name == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label class="label asterisk">주문 담당자 이름</label>
					<input
						class=""
						type="text"
						id="name"
						placeholder="주문 담당자를 입력해주세요"
						v-model="companyManagerInfo.name"
						disabled
						@input="
							[
								checkInputLength($event, 2, 64)
									? (companyManagerInfoValidClass.name = 'valid')
									: (companyManagerInfoValidClass.name = 'error'),
							];
							setIssuedBillManager(companyManagerInfo.issuedBillManagerSame);
						"
					/>
					<p v-if="companyManagerInfoValidClass.name == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</p>
					<p v-if="companyManagerInfoValidClass.name == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자명은 필수 입력 항목입니다.
					</p>
				</div>
				<!-- 이메일 -->
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.email == 'error' || companyManagerInfoValidClass.email == 'empty'
							? 'error'
							: companyManagerInfoValidClass.email == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label class="label asterisk">이메일</label>
					<input
						class=""
						type="text"
						id="email"
						placeholder="이메일을 입력해주세요"
						v-model="companyManagerInfo.email"
						disabled
						@keyup="
							[
								checkEmail(companyManagerInfo.email)
									? (companyManagerInfoValidClass.email = 'valid')
									: (companyManagerInfoValidClass.email = 'error'),
							];
							setIssuedBillManager(companyManagerInfo.issuedBillManagerSame);
						"
					/>
					<p v-if="companyManagerInfoValidClass.email == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>이메일 형식이 올바르지 않습니다.
					</p>
					<p v-if="companyManagerInfoValidClass.email == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>담당자 이메일은 필수 항목입니다.
					</p>
				</div>
			</div>
			<!-- 휴대전화 -->
			<div class="group row col1 half">
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.phone == 'error' || companyManagerInfoValidClass.phone == 'empty'
							? 'error'
							: companyManagerInfoValidClass.phone == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label class="label asterisk">휴대전화</label>
					<input
						class=""
						type="text"
						id="phone"
						placeholder="휴대전화를 입력해주세요"
						value="01099990000"
						v-model="companyManagerInfo.phone"
						disabled
						@keyup="
							[
								checkPhone(companyManagerInfo.phone)
									? (companyManagerInfoValidClass.phone = 'valid')
									: (companyManagerInfoValidClass.phone = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.phone == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						번호형식이 올바르지 않습니다. (-, +, 0~9)
					</p>
					<p v-if="companyManagerInfoValidClass.phone == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						담당자 휴대전화는 필수 항목입니다.
					</p>
				</div>
			</div>
			<!-- 세금계산서 담당자 정보 확인 체크 -->
			<div class="group row col1">
				<div
					class="group-chk"
					@click="
						() => {
							this.companyManagerInfo.issuedBillManagerSame = !this.companyManagerInfo.issuedBillManagerSame;
							setIssuedBillManager(this.companyManagerInfo.issuedBillManagerSame);
						}
					"
				>
					<input type="checkbox" v-model="companyManagerInfo.issuedBillManagerSame" />
					<label class="label">세금계산서 담당자가 담당자 정보와 동일 </label>
				</div>
				<span class="">
					<i class="ic-16 ic-info">
						<span class="tooltip-layer left"> 세금계산서 담당자 이메일로 세금계산서 관련 메일이 발송됩니다. </span>
					</i>
				</span>
			</div>
			<div class="group row col2 gap40 align-start">
				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.issuedBillName == 'error' ||
						companyManagerInfoValidClass.issuedBillName == 'empty'
							? 'invalid'
							: companyManagerInfoValidClass.issuedBillName == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label class="label asterisk">세금계산서 담당자명</label>
					<input
						type="text"
						id="issuedBillName"
						placeholder="세금계산서 담당자를 입력해주세요"
						v-model="companyManagerInfo.issuedBillName"
						:disabled="companyManagerInfo.issuedBillManagerSame"
						@input="
							[
								checkInputLength($event, 2, 64)
									? (companyManagerInfoValidClass.issuedBillName = 'valid')
									: (companyManagerInfoValidClass.issuedBillName = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.issuedBillName == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						세금계산서 담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</p>
					<p v-if="companyManagerInfoValidClass.issuedBillName == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						세금계산서 담당자명은 필수 입력 항목입니다.
					</p>
				</div>

				<div
					class="group-inp"
					:class="[
						companyManagerInfoValidClass.issuedBillEmail == 'error' ||
						companyManagerInfoValidClass.issuedBillEmail == 'empty'
							? 'invalid'
							: companyManagerInfoValidClass.issuedBillEmail == 'valid'
							? 'valid'
							: '',
					]"
				>
					<label class="label asterisk">세금계산서 담당자 이메일</label>
					<input
						type="text"
						id="issuedBillEmail"
						placeholder="세금계산서 담당자 이메일을 입력해주세요"
						v-model="companyManagerInfo.issuedBillEmail"
						:disabled="companyManagerInfo.issuedBillManagerSame"
						@keyup="
							[
								checkEmail(companyManagerInfo.issuedBillEmail)
									? (companyManagerInfoValidClass.issuedBillEmail = 'valid')
									: (companyManagerInfoValidClass.issuedBillEmail = 'error'),
							]
						"
					/>
					<p v-if="companyManagerInfoValidClass.issuedBillEmail == 'error'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						세금계산서 담당자 이메일 형식이 올바르지 않습니다.
					</p>
					<p v-if="companyManagerInfoValidClass.issuedBillEmail == 'empty'" class="text-detail invalid">
						<i class="ic-16 ic-invalid"></i>
						세금계산서 담당자 이메일은 필수 입력 항목입니다.
					</p>
				</div>
			</div>
			<!-- <div class="row"> @0329 견적/주문 관리v2.0에 의해 유선전화&직급 삭제
          <div class="com_ipt_floating">
            <input class="" type="text" placeholder="." value="01099990000" v-model="companyManagerInfo.tel" />
            <label>유선전화</label>
          </div>
        </div>
        <div class="row">
          <div class="com_ipt_floating">
            <input class="" type="text" placeholder="." v-model="companyManagerInfo.grade" />
            <label>직급</label>
          </div>
        </div> -->
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';

export default {
	mixins: [validation],
	data() {
		const currentUser = this.$store.state.login.currentUser;
		const { companyManagerInfo, companyInfo } = this.$store.getters.getAllCompanyInfo;
		let corporateRepresentativeCheck = currentUser.accountRoleType === 'SUPER ADMIN';
		const origin = {
			issuedBillManagerSame: false,
			name: companyManagerInfo.name || currentUser.name || '',
			grade: companyManagerInfo.grade || currentUser.rank || '',
			phone: companyManagerInfo.phone || currentUser.phoneNumber || '',
			tel: companyManagerInfo.tel || currentUser.tel || '',
			email: companyManagerInfo.email || currentUser.email || '',
			issuedBillName: companyInfo?.taxInvoiceManager?.taxInvoiceManagerName || '',
			issuedBillEmail: companyInfo?.taxInvoiceManager?.taxInvoiceEmail || '',
			isCorporateRepresentativeCheck: '',
		};

		return {
			corporateRepresentativeCheck,
			companyManagerInfo: {
				issuedBillManagerSame: origin.issuedBillManagerSame,
				name: origin.name, // 필수
				grade: origin.grade,
				phone: origin.phone, // 필수
				tel: origin.tel, // 필수
				email: origin.email, // 필수
				issuedBillName: origin.issuedBillName, // 필수
				issuedBillEmail: origin.issuedBillEmail, // 필수
				isCorporateRepresentativeCheck: origin.isCorporateRepresentativeCheck,
			},
			companyManagerInfoValidClass: {
				name: origin.name ? 'valid' : '',
				grade: 'valid',
				phone: origin.phone ? 'valid' : '',
				tel: 'valid',
				email: origin.email ? 'valid' : '',
				issuedBillName: origin.issuedBillName ? 'valid' : '',
				issuedBillEmail: origin.issuedBillEmail ? 'valid' : '',
				isCorporateRepresentativeCheck: corporateRepresentativeCheck ? 'valid' : '',
			},
			isSuperAdmin: currentUser.accountRoleType === 'SUPER ADMIN',
		};
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
		saveFlag() {
			if (this.saveFlag) {
				// TODO: 존재하지 않는 코드
				this.saveCompanyManagerInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyManagerInfo)) {
				const key = Object.keys(this.companyManagerInfo)[idx];
				const value = this.companyManagerInfo[key];

				if (key == 'name' || key == 'phone' || key == 'email' || key == 'issuedBillName' || key == 'issuedBillEmail') {
					if (!value || value.length == 0) {
						this.companyManagerInfoValidClass[key] = 'empty';
					}
				}
				if (key === 'isCorporateRepresentativeCheck') {
					this.companyManagerInfoValidClass.isCorporateRepresentativeCheck = this.corporateRepresentativeCheck
						? 'valid'
						: 'empty';
				}
				if (key !== 'issuedBillManagerSame' && this.companyManagerInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		setIssuedBillManager(issuedBillManagerSame) {
			if (issuedBillManagerSame) {
				this.companyManagerInfo.issuedBillName = this.companyManagerInfo.name;
				this.companyManagerInfo.issuedBillEmail = this.companyManagerInfo.email;
				this.companyManagerInfoValidClass.issuedBillName = this.companyManagerInfoValidClass.name;
				this.companyManagerInfoValidClass.issuedBillEmail = this.companyManagerInfoValidClass.email;
			}
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyManagerInfo',
				companyManagerInfo: this.companyManagerInfo,
				companyManagerInfoValidClass: this.companyManagerInfoValidClass,
			});
		},
		clickCheckManagerAgreement() {
			this.corporateRepresentativeCheck = !this.corporateRepresentativeCheck;
			this.companyManagerInfoValidClass.isCorporateRepresentativeCheck = this.corporateRepresentativeCheck
				? 'valid'
				: 'empty';
		},
	},
};
</script>

<style></style>
