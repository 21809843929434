<template>
	<span v-on:click="checkBusinessLicenseNumber()">
		<slot>
			<button class="line" :disabled="disabled">
				<span class="" :disabled="disabled">사업자진위 확인</span>
			</button>
		</slot>
	</span>
</template>

<script>
export default {
	props: {
		value: {},
		businessLicenseNumber: {
			type: String,
			default: '',
		},
		disabled: {},
	},
	data() {
		return {
			businessInfoConfirm: this.value,
		};
	},
	methods: {
		emitConfim() {
			this.$emit('input', this.businessInfoConfirm);
		},
		async checkBusinessLicenseNumber() {
			if (this.businessLicenseNumber == '9999999999') {
				this.success();
				return;
			}
			await this.$store
				.dispatch('checkBusinessLicenseNumber', {
					businessLicenseNumber: this.businessLicenseNumber.replace(/-/g, ''),
				})
				.then((res) => {
					res.data.available ? this.success() : this.fail(res.data.message);
				});
		},
		success() {
			this.$toasted.show('확인되었습니다.');
			this.businessInfoConfirm = true;
			this.emitConfim();
		},
		fail(message) {
			this.$toasted.show(message);
			this.businessInfoConfirm = false;
			this.emitConfim();
		},
	},
};
</script>

<style></style>
