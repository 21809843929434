<template>
	<Fragment>
		<div class="section">
			<h3 class="heading-3-b mb-20">개설 정보</h3>
			<!--@--개설 정보 : 클라우드 공유형 saas-->
			<div class="contents group column gap20" v-for="item in this.basicItemSelected" :key="item.code">
				<h4 class="subheading-m">{{ item.serviceCategoryModel.name }}</h4>
				<div class="group row col2 gap40 align-start">
					<div
						class="group-inp"
						:class="[
							companySiteInfoValidClass.basicItem.siteName == 'valid' ||
							companySiteInfoValidClass.basicItem.siteName == ''
								? 'valid'
								: 'invalid',
						]"
					>
						<label class="label asterisk"
							>회사명(사이트명)
							<span class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left"> 다우오피스 접속 시 노출 될 회사명을 입력해주세요. </span>
								</i>
							</span>
						</label>
						<input
							class=""
							type="text"
							id="siteName"
							placeholder="회사명(사이트명)을 입력해주세요"
							v-model="companySiteInfo.basicItem.siteName"
							@input="validSpecialInput($event)"
						/>
						<p v-if="companySiteInfoValidClass.basicItem.siteName === 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							회사명(사이트명)은 2자 이상, 64자 이하를 입력해야 합니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.siteName === 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							회사명(사이트명)은 필수 입력 항목입니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.siteName === 'invalid'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							회사명(사이트명)에 | \\ " # $ &lt; &gt; , ; 특수문자는 사용 불가합니다.
						</p>
					</div>
					<div
						class="group-inp"
						:class="[
							companySiteInfoValidClass.basicItem.masterId == 'valid' ||
							companySiteInfoValidClass.basicItem.masterId == ''
								? 'valid'
								: 'invalid',
						]"
					>
						<label class="label asterisk"
							>관리자 ID
							<span class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left">
										입력된 관리자 ID로 그룹웨어와 경리회계가 개설됩니다. 해당 계정으로 그룹웨어와 경리회계 설정을
										해야하므로 설정이 끝날때까지 계정 정보 변경을 삼가주시기 바랍니다.
									</span>
								</i>
							</span>
						</label>
						<input
							class=""
							type="text"
							id="masterId"
							placeholder="관리자 ID를 입력해주세요"
							v-model="companySiteInfo.basicItem.masterId"
							@keyup="
								checkId(companySiteInfo.basicItem.masterId)
									? (companySiteInfoValidClass.basicItem.masterId = 'valid')
									: (companySiteInfoValidClass.basicItem.masterId = 'error')
							"
							@input="validateMasterId"
						/>
						<p v-if="companySiteInfoValidClass.basicItem.masterId == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							관리자 ID는 3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 만 입력해야 합니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.masterId == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							관리자 ID는 필수 입력 항목입니다.
						</p>
					</div>
				</div>
				<div class="group row col2 gap40 align-start">
					<div
						class="group-inp"
						:class="[
							isTypicalizedStatus(companySiteInfoValidClass.basicItem.domain)
								? [
										companySiteInfoValidClass.basicItem.domain == 'empty' ||
										companySiteInfoValidClass.basicItem.domain == 'needConfirm' ||
										companySiteInfoValidClass.basicItem.domain == 'error'
											? 'invalid'
											: companySiteInfoValidClass.basicItem.domain,
								  ]
								: 'invalid',
						]"
					>
						<label class="label asterisk"
							>메일 도메인
							<span class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left">
										미사용을 체크하여 신청한 후에 <br />
										메일 사용을 원하는 경우, <br />
										고객센터로 문의주시기 바랍니다.
									</span>
								</i>
							</span>
						</label>
						<!--button layout-->
						<div class="group-inp-btn">
							<!--@확인 버튼 노출-->
							<input
								class=""
								type="text"
								id="domain"
								placeholder="ex) daouoffice.com"
								v-model="companySiteInfo.basicItem.domain"
								:disabled="companySiteInfo.basicItem.notUseDaouMail"
								@input="validateMailDomain"
							/>
							<button
								class="line"
								:disabled="companySiteInfo.basicItem.notUseDaouMail"
								@click="checkMailDomain(companySiteInfo.basicItem.domain)"
								@mouseover="
									[
										companySiteInfoValidClass.basicItem.domain == ''
											? (companySiteInfoValidClass.basicItem.domain = 'active')
											: (companySiteInfoValidClass.basicItem.domain = companySiteInfoValidClass.basicItem.domain),
									]
								"
								@mouseleave="
									[
										companySiteInfoValidClass.basicItem.domain == 'active'
											? (companySiteInfoValidClass.basicItem.domain = '')
											: (companySiteInfoValidClass.basicItem.domain = companySiteInfoValidClass.basicItem.domain),
									]
								"
							>
								<span class="txt">확인</span>
							</button>
						</div>
						<p
							v-if="companySiteInfoValidClass.basicItem.domain == 'valid' && !companySiteInfo.basicItem.notUseDaouMail"
							class="text-detail valid"
						>
							<i class="ic-16 ic-valid"></i>
							사용할 수 있는 도메인입니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.domain == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							메일 도메인은 3자 이상, 64자 이하의 영문, 숫자, -, . 만 입력해야 합니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.domain == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							메일 도메인은 필수 입력 항목입니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.domain == 'needConfirm'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							입력하신 메일 도메인에 대한 [확인]이 필요합니다.
						</p>
						<p v-if="!isTypicalizedStatus(companySiteInfoValidClass.basicItem.domain)" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							{{ companySiteInfoValidClass.basicItem.domain }}
						</p>
						<div class="group column">
							<p class="option-guide mt-8">
								메일 사용을 위해 도메인 구매를 희망하시는 분들은 구매 가이드를 참고해주세요.
								<a v-on:click="domainGuide" class="text-link primary">도메인 구매 가이드</a>
							</p>
						</div>
						<div class="group-chk mt-8" @click="notUseDaouMail">
							<input type="checkbox" v-model="companySiteInfo.basicItem.notUseDaouMail" />
							<label class="label">다우오피스에서 메일을 사용하지 않겠습니다.</label>
						</div>
					</div>
					<div
						class="group-inp"
						:class="[
							isTypicalizedStatus(companySiteInfoValidClass.basicItem.url)
								? [
										companySiteInfoValidClass.basicItem.url == 'empty' ||
										companySiteInfoValidClass.basicItem.url == 'error'
											? 'invalid'
											: companySiteInfoValidClass.basicItem.url,
								  ]
								: 'invalid',
						]"
					>
						<label class="label asterisk">
							접속 URL
							<span class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left">
										다우오피스에 접속할 수 있는 회사만의 고유 주소입니다. ex) test.mydomain.co.kr
									</span>
								</i>
							</span>
						</label>
						<div class="group row align-middle">
							<span class="txt large">http://</span>
							<div class="group-inp-btn">
								<input
									class="asterisk"
									type="text"
									id="url"
									placeholder="접속 URL을 입력해주세요"
									value="ddedde.daouoffice.co.kr"
									v-model="companySiteInfo.basicItem.url"
									@input="validateUrl"
								/>
								.daouoffice.com
								<button class="line" @click="checkBaseUrl">
									<span class="txt">확인</span>
								</button>
							</div>
						</div>
						<p v-if="companySiteInfoValidClass.basicItem.url == 'valid'" class="text-detail valid">
							<i class="ic-16 ic-valid"></i>
							사용할 수 있는 주소입니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.url == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							접속 URL은 1자 이상, 64자 이하의 영문, 숫자, - 만 입력해야 합니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.url == 'empty'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							접속 URL은 필수 입력 항목입니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.url == 'needConfirm'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							입력하신 접속 URL에 대한 [확인]이 필요합니다.
						</p>
						<p v-if="!isTypicalizedStatus(companySiteInfoValidClass.basicItem.url)" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							{{ companySiteInfoValidClass.basicItem.url }}
						</p>
					</div>
				</div>
			</div>
			<div class="contents group column gap20" v-if="$utils.isOrderMultiCompany()">
				<h4 class="subheading-m">멀티컴퍼니 구성</h4>
				<div class="group row col1 half">
					<div
						class="group-inp"
						:class="[
							isTypicalizedStatus(companySiteInfoValidClass.basicItem.doClusterId)
								? [
										companySiteInfoValidClass.basicItem.doClusterId == 'empty' ||
										companySiteInfoValidClass.basicItem.doClusterId == 'needConfirm'
											? 'invalid'
											: companySiteInfoValidClass.basicItem.doClusterId,
								  ]
								: 'invalid',
						]"
					>
						<label class="label asterisk">
							멀티컴퍼니로 구성할 기존 다우오피스의 접속 URL
							<span class="">
								<i class="ic-16 ic-info">
									<span class="tooltip-layer left">
										• 원활한 멀티컴퍼니 구성을 위해 기존 다우오피스를 사용중인 회사명(사이트)을 반드시 확인 해주시기
										바랍니다.<br />
										• 멀티컴퍼니 구성 작업은 각 회사(사이트) 담당자 확인 후 진행될 예정입니다.<br />
										• 세 개 이상 멀티컴퍼니 구성을 원하실 경우 다우오피스 고객케어라운지 혹은 tel.1577-3019로 문의주시기
										바랍니다.<br />
									</span>
								</i>
							</span>
						</label>
						<div class="group-inp-btn">
							<!--@확인 버튼 노출-->
							<input class="" type="text" placeholder="" v-model="companySiteInfo.basicItem.doClusterUrl" />
							.daouoffice.com
							<button class="line" @click="checkMultiSiteUrl(companySiteInfo.basicItem.doClusterUrl)">
								<span class="txt">확인</span>
							</button>
						</div>
						<p v-if="companySiteInfoValidClass.basicItem.doClusterId == 'valid'" class="text-detail valid">
							<i class="ic-16 ic-valid"></i>
							회사명(사이트명)
							<span style="font-weight: bold"> '{{ companySiteInfo.basicItem.multiSiteName }}' </span>
							과(와) 멀티컴퍼니를 구성합니다.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.doClusterId == 'error'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							다우오피스를 사용중인 고객사가 아닙니다. 접속 URL을 다시 확인해주세요.
						</p>
						<p v-if="companySiteInfoValidClass.basicItem.doClusterId == 'needConfirm'" class="text-detail invalid">
							<i class="ic-16 ic-invalid"></i>
							입력하신 접속 URL에 대한 [확인]이 필요합니다.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import mixinsAlert from '@/components/common/mixinsAlert';
import { Fragment } from 'vue-fragment';
import { validation } from '@/components/apply/mixinsValidation.js';
import { DOMAIN_BUYING_GUIDE } from '@/temlplates';

export default {
	components: { Fragment },
	mixins: [validation, mixinsAlert],
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const origin = this.$store.getters.getAllCompanyInfo.companySiteInfo;
		const basicItemSelected = this.$store.getters.getBasicItemSelected; // 1개
		const addonItemSelected = this.$store.getters.getAddonItemSelected; // 0 ~ n개
		const addonItemData = [];
		const addonItemValid = [];
		if (addonItemSelected.length > 0) {
			for (let i = 0; i < addonItemSelected.length; i++) {
				addonItemData.push({
					name: addonItemSelected[i].name,
					masterId: origin
						? origin.addonItem
							? origin.addonItem.length > i && origin.addonItem[i].masterId
								? origin.addonItem[i].masterId
								: ''
							: ''
						: '',
				});
				addonItemValid.push({
					masterId: origin
						? origin.addonItem
							? origin.addonItem.length > i && origin.addonItem[i].masterId
								? 'valid'
								: ''
							: ''
						: '',
				});
			}
		}

		return {
			validDomain: '',
			validUrl: '',
			basicItemSelected,
			addonItemSelected,
			companySiteInfo: {
				basicItem: {
					name: basicItemSelected[0] ? basicItemSelected[0].name : '', //필수
					siteName: origin
						? origin.basicItem
							? origin.basicItem.siteName
								? origin.basicItem.siteName
								: ''
							: ''
						: '', //필수
					masterId: origin
						? origin.basicItem
							? origin.basicItem.masterId
								? origin.basicItem.masterId
								: ''
							: ''
						: '', //필수
					domain: origin ? (origin.basicItem ? (origin.basicItem.domain ? origin.basicItem.domain : '') : '') : '', //필수
					url: origin ? (origin.basicItem ? (origin.basicItem.url ? origin.basicItem.url : '') : '') : '', //필수
					notUseDaouMail: origin
						? origin.basicItem
							? origin.basicItem.notUseDaouMail
								? origin.basicItem.notUseDaouMail
								: false
							: false
						: false,
					doClusterId: '', //클러스터 ID
					doClusterUrl: '',
					multiSiteName: '', //묶일 사이트명
					doClusterName: '', //클러스터명
				},
				addonItem: addonItemData,
			},
			companySiteInfoValidClass: {
				basicItem: {
					siteName: origin ? (origin.basicItem ? (origin.basicItem.siteName ? 'valid' : '') : '') : '',
					masterId: origin ? (origin.basicItem ? (origin.basicItem.masterId ? 'valid' : '') : '') : '',
					domain: origin ? (origin.basicItem ? (origin.basicItem.domain ? 'valid' : '') : '') : '',
					url: origin ? (origin.basicItem ? (origin.basicItem.url ? 'valid' : '') : '') : '',
					doClusterId: '',
				},
				addonItem: addonItemValid,
			},
		};
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
				if (this.$utils.isOrderMultiCompany()) {
					await this.checkMultiCompanyValidInfo();
				}
			}
		},
	},
	methods: {
		validSpecialInput(event) {
			if (this.checkSpecialChar(event)) {
				this.companySiteInfoValidClass.basicItem.siteName = 'invalid';
			} else if (event.target.value.trim().length == 0) {
				this.companySiteInfoValidClass.basicItem.siteName = 'empty';
			} else if (!this.checkInputLengthTrim(event, 2, 64)) {
				this.companySiteInfoValidClass.basicItem.siteName = 'error';
			} else {
				this.companySiteInfoValidClass.basicItem.siteName = 'valid';
			}
		},
		async checkMultiCompanyValidInfo() {
			if (this.companySiteInfo.basicItem.multiSiteName == '') {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'needConfirm';
				return false;
			}
			return true;
		},
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companySiteInfo.basicItem)) {
				const key = Object.keys(this.companySiteInfo.basicItem)[idx];
				const value = this.companySiteInfo.basicItem[key];

				if (key !== 'notUseDaouMail') {
					if (!value || value.length == 0) {
						if (!(key == 'domain' && this.companySiteInfo.basicItem.notUseDaouMail)) {
							this.companySiteInfoValidClass.basicItem[key] = 'empty';
						}
					}
				}
				if (key !== 'name' && key !== 'notUseDaouMail' && this.companySiteInfoValidClass.basicItem[key] !== 'valid') {
					if (
						this.companySiteInfoValidClass.basicItem[key] == '' ||
						((key == 'domain' || key == 'url') &&
							this.companySiteInfoValidClass.basicItem[key] == 'empty' &&
							!(!value || value.length == 0))
					) {
						this.companySiteInfoValidClass.basicItem[key] = 'needConfirm';
					}

					isAllValid = false;
				}
			}

			if (this.companySiteInfo.addonItem.length > 0) {
				for (let i = 0; i < this.companySiteInfo.addonItem.length; i++) {
					const addon = this.companySiteInfo.addonItem[i];
					if (!addon.masterId || addon.masterId.length == 0) {
						this.companySiteInfoValidClass.addonItem[i].masterId = 'empty';
					}

					if (this.companySiteInfoValidClass.addonItem[i].masterId !== 'valid') {
						if (
							this.companySiteInfoValidClass.addonItem[i].masterId == '' ||
							(this.companySiteInfoValidClass.addonItem[i].masterId == 'empty' &&
								!(!addon.masterId || addon.masterId.length == 0))
						) {
							this.companySiteInfoValidClass.addonItem[i].masterId = 'needConfirm';
						}

						isAllValid = false;
					}
				}
			}

			return isAllValid;
		},
		validateMasterId(e) {
			let masterId = e.currentTarget.value;
			let masterIdPattern = /[^a-z0-9-_.]/;

			if (!masterId) {
				this.companySiteInfoValidClass.basicItem.masterId = 'empty';
				return false;
			} else if (masterId.match(masterIdPattern) || !this.checkInputLengthTrim(e, 3, 64)) {
				this.companySiteInfoValidClass.basicItem.masterId = 'error';
				return false;
			}

			this.companySiteInfoValidClass.basicItem.masterId = 'valid';
			this.masterIdAutoMapping(this.companySiteInfo.basicItem.masterId);
		},
		masterIdAutoMapping(baseMasterId) {
			if (this.companySiteInfoValidClass.basicItem.masterId == 'valid') {
				this.companySiteInfo.addonItem.forEach((item) => (item.masterId = baseMasterId));

				this.companySiteInfoValidClass.addonItem.forEach((item) => (item.masterId = 'valid'));
			}
		},
		isTypicalizedStatus(validationStatus) {
			let flag = true;
			if (
				validationStatus &&
				validationStatus !== 'valid' &&
				validationStatus !== 'error' &&
				validationStatus !== 'active' &&
				validationStatus !== 'empty' &&
				validationStatus !== 'needConfirm'
			) {
				flag = false;
			}
			return flag;
		},
		notUseDaouMail() {
			this.companySiteInfo.basicItem.notUseDaouMail = !this.companySiteInfo.basicItem.notUseDaouMail;
			if (this.companySiteInfo.basicItem.notUseDaouMail) {
				this.companySiteInfo.basicItem.domain = '';
				this.companySiteInfoValidClass.basicItem.domain = 'valid';
			} else {
				this.companySiteInfoValidClass.basicItem.domain = '';
			}
		},
		validateMailDomain(e) {
			let domain = e.currentTarget.value;

			if (!domain) {
				this.companySiteInfoValidClass.basicItem.domain = 'empty';
				return false;
			}

			// TODO: 해당 기능 확인 필요
			if (domain.indexOf('del.') == 0) {
				this.companySiteInfoValidClass.basicItem.domain = 'error';
				return false;
			}

			if (!this.checkInputLengthTrim(e, 3, 64)) {
				this.companySiteInfoValidClass.basicItem.domain = 'error';
				return false;
			}

			var domainTest = domain;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(domainTest.replace(/[\\.-]/g, ''))) {
				this.companySiteInfoValidClass.basicItem.domain = 'error';
				return false;
			}

			this.companySiteInfoValidClass.basicItem.domain = 'needConfirm';
		},
		async checkMailDomain(domain) {
			const options = this.$store.getters.getBasicItemSelected[0].optionItemSelected;

			const virtualDomain = options.filter((option) => option.itemType == 'VIRTUAL_DOMAIN');
			if (virtualDomain.length > 0) {
				const dupleDomainText = virtualDomain[0].domainText.filter((text) => text == domain);
				if (dupleDomainText.length > 0) {
					this.companySiteInfoValidClass.basicItem.domain =
						'메일 도메인은 가상도메인과 같은 주소로 사용할 수 없습니다.';
					return false;
				}
			}

			const response = await this.$store.dispatch('checkAdmin', domain);
			if (response.data) {
				this.companySiteInfoValidClass.basicItem.domain = 'valid';
				return true;
			} else {
				this.companySiteInfoValidClass.basicItem.domain = response.errorDescription;
				return false;
			}
		},
		async checkMultiSiteUrl(urlInput) {
			if (!urlInput) {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'error';
				return false;
			}

			var pattern_period = /[.]/;
			if (pattern_period.test(urlInput)) {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'error';
				return false;
			}

			const url = urlInput + '.daouoffice.com';
			if (url.length < 1 || url.length > 64) {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'error';
				return false;
			}
			var urlTest = url;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(urlTest.replace(/[\\_.-]/g, ''))) {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'error';
				return false;
			}

			//멀티컴퍼니 조회
			const { data } = await this.$store.dispatch('checkMultiCompany', {
				url: url,
			});
			if (data) {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'valid';
				this.companySiteInfo.basicItem.doClusterId = data.clusterId;
				this.companySiteInfo.basicItem.multiSiteName = data.siteName;
				this.companySiteInfo.basicItem.doClusterName = data.clusterName;
			} else {
				this.companySiteInfoValidClass.basicItem.doClusterId = 'error';
			}
			return true;
		},
		resetValidUrl() {
			if (this.validUrl != this.companySiteInfo.basicItem.url) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
			} else {
				this.companySiteInfoValidClass.basicItem.url = 'valid';
			}
		},
		validateUrl(e) {
			let urlInput = e.target.value.trim();

			if (urlInput.length === 0) {
				this.companySiteInfoValidClass.basicItem.url = 'empty';
				return false;
			}

			var pattern_period = /[.]/;
			if (pattern_period.test(urlInput)) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
				return false;
			}

			let urlPattern = /[^a-zA-Z0-9-]/;
			if (urlInput.match(urlPattern)) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
				return false;
			}

			if (!this.checkInputLengthTrim(e, 1, 64)) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
				return false;
			}

			let urlTest = urlInput + '.daouoffice.com';
			let pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(urlTest.replace(/[\\_.-]/g, ''))) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
				return false;
			}

			this.companySiteInfoValidClass.basicItem.url = 'needConfirm';
			return false;
		},
		async checkBaseUrl() {
			let urlInput = this.companySiteInfo.basicItem.url;
			let url = urlInput + '.daouoffice.com';
			if (this.companySiteInfoValidClass.basicItem.url !== 'needConfirm') {
				return false;
			}

			//중복체크
			const response = await this.$store.dispatch('checkUrl', url);
			if (response.data) {
				this.companySiteInfoValidClass.basicItem.url = 'valid';
				this.validUrl = urlInput;
			} else {
				this.companySiteInfoValidClass.basicItem.url = 'error';
			}
			return true;
		},
		async checkAddonUrl(url) {
			if (url.length < 1 || url.length > 64) {
				return false;
			}

			var urlTest = url;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(urlTest.replace(/[\\_.-]/g, ''))) {
				return false;
			}
			return true;
		},
		removeEmpty(val) {
			return val.replace(/\s+/, '').replace(/\s+$/g, '').replace(/\n/g, '').replace(/\r/g, '');
		},
		async sendValidInfo() {
			if (this.companySiteInfo.basicItem.notUseDaouMail) {
				this.companySiteInfoValidClass.basicItem.domain = 'valid';
			}

			if (
				this.companySiteInfoValidClass.basicItem.domain !== 'valid' &&
				this.companySiteInfoValidClass.basicItem.domain !== 'needConfirm'
			) {
				this.companySiteInfoValidClass.basicItem.domain = 'error';
			}

			if (
				this.companySiteInfoValidClass.basicItem.url !== 'valid' &&
				this.companySiteInfoValidClass.basicItem.url !== 'needConfirm' &&
				this.companySiteInfoValidClass.basicItem.domain !== 'needConfirm'
			) {
				this.companySiteInfoValidClass.basicItem.url = 'error';
			}

			this.companySiteInfo.basicItem.masterId = this.removeEmpty(this.companySiteInfo.basicItem.masterId);
			this.companySiteInfo.basicItem.domain = this.removeEmpty(this.companySiteInfo.basicItem.domain);
			this.companySiteInfo.basicItem.url = this.removeEmpty(this.companySiteInfo.basicItem.url);

			var tempAddonItem = this.companySiteInfo.addonItem;
			this.companySiteInfo.addonItem = [];
			tempAddonItem.forEach((data) => {
				data.masterId = this.removeEmpty(data.masterId);
				this.companySiteInfo.addonItem.push(data);
			});

			this.$emit('gatherData', {
				type: 'companySiteInfo',
				companySiteInfo: this.companySiteInfo,
				companySiteInfoValidClass: this.companySiteInfoValidClass,
			});
		},

		domainGuide() {
			this.alert({
				title: '도메인 구매 가이드',
				contents: DOMAIN_BUYING_GUIDE,
				styleType: 'medium',
			});
		},
	},
};
</script>

<style></style>
