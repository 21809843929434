var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('h3',{staticClass:"heading-3-b mb-20"},[_vm._v("담당자 정보")]),_c('div',{staticClass:"contents group column gap20"},[_c('div',{staticClass:"group row col1"},[(!_vm.isSuperAdmin)?_c('div',{staticClass:"group-chk"},[_c('input',{attrs:{"id":"isCorporateRepresentativeCheck","type":"checkbox"},domProps:{"checked":this.corporateRepresentativeCheck},on:{"click":_vm.clickCheckManagerAgreement}}),_c('label',{staticClass:"label text-detail",class:{ invalid: _vm.companyManagerInfoValidClass.isCorporateRepresentativeCheck != 'valid' },on:{"click":_vm.clickCheckManagerAgreement}},[_vm._v("[필수] 다우오피스 신규 주문시 약관 동의를 통해 다우기술과 서비스 이용 계약을 체결한 법인의 대표(혹은 법인 대리)인 관리자로 전환됩니다. ")])]):_vm._e()]),(_vm.companyManagerInfoValidClass.isCorporateRepresentativeCheck === 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 약관 동의는 필수입니다. ")]):_vm._e(),_c('div',{staticClass:"group row col2 gap40 align-start"},[_c('div',{staticClass:"group-inp",class:[
						_vm.companyManagerInfoValidClass.name == 'error' || _vm.companyManagerInfoValidClass.name == 'empty'
							? 'error'
							: _vm.companyManagerInfoValidClass.name == 'valid'
							? 'valid'
							: '',
					]},[_c('label',{staticClass:"label asterisk"},[_vm._v("주문 담당자 이름")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.name),expression:"companyManagerInfo.name"}],attrs:{"type":"text","id":"name","placeholder":"주문 담당자를 입력해주세요","disabled":""},domProps:{"value":(_vm.companyManagerInfo.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "name", $event.target.value)},function($event){[
								_vm.checkInputLength($event, 2, 64)
									? (_vm.companyManagerInfoValidClass.name = 'valid')
									: (_vm.companyManagerInfoValidClass.name = 'error'),
							];
							_vm.setIssuedBillManager(_vm.companyManagerInfo.issuedBillManagerSame);}]}}),(_vm.companyManagerInfoValidClass.name == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.name == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"group-inp",class:[
						_vm.companyManagerInfoValidClass.email == 'error' || _vm.companyManagerInfoValidClass.email == 'empty'
							? 'error'
							: _vm.companyManagerInfoValidClass.email == 'valid'
							? 'valid'
							: '',
					]},[_c('label',{staticClass:"label asterisk"},[_vm._v("이메일")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.email),expression:"companyManagerInfo.email"}],attrs:{"type":"text","id":"email","placeholder":"이메일을 입력해주세요","disabled":""},domProps:{"value":(_vm.companyManagerInfo.email)},on:{"keyup":function($event){[
								_vm.checkEmail(_vm.companyManagerInfo.email)
									? (_vm.companyManagerInfoValidClass.email = 'valid')
									: (_vm.companyManagerInfoValidClass.email = 'error'),
							];
							_vm.setIssuedBillManager(_vm.companyManagerInfo.issuedBillManagerSame);},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "email", $event.target.value)}}}),(_vm.companyManagerInfoValidClass.email == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v("이메일 형식이 올바르지 않습니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.email == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v("담당자 이메일은 필수 항목입니다. ")]):_vm._e()])]),_c('div',{staticClass:"group row col1 half"},[_c('div',{staticClass:"group-inp",class:[
						_vm.companyManagerInfoValidClass.phone == 'error' || _vm.companyManagerInfoValidClass.phone == 'empty'
							? 'error'
							: _vm.companyManagerInfoValidClass.phone == 'valid'
							? 'valid'
							: '',
					]},[_c('label',{staticClass:"label asterisk"},[_vm._v("휴대전화")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.phone),expression:"companyManagerInfo.phone"}],attrs:{"type":"text","id":"phone","placeholder":"휴대전화를 입력해주세요","value":"01099990000","disabled":""},domProps:{"value":(_vm.companyManagerInfo.phone)},on:{"keyup":function($event){[
								_vm.checkPhone(_vm.companyManagerInfo.phone)
									? (_vm.companyManagerInfoValidClass.phone = 'valid')
									: (_vm.companyManagerInfoValidClass.phone = 'error'),
							]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "phone", $event.target.value)}}}),(_vm.companyManagerInfoValidClass.phone == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 번호형식이 올바르지 않습니다. (-, +, 0~9) ")]):_vm._e(),(_vm.companyManagerInfoValidClass.phone == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 담당자 휴대전화는 필수 항목입니다. ")]):_vm._e()])]),_c('div',{staticClass:"group row col1"},[_c('div',{staticClass:"group-chk",on:{"click":() => {
							this.companyManagerInfo.issuedBillManagerSame = !this.companyManagerInfo.issuedBillManagerSame;
							_vm.setIssuedBillManager(this.companyManagerInfo.issuedBillManagerSame);
						}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.issuedBillManagerSame),expression:"companyManagerInfo.issuedBillManagerSame"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.companyManagerInfo.issuedBillManagerSame)?_vm._i(_vm.companyManagerInfo.issuedBillManagerSame,null)>-1:(_vm.companyManagerInfo.issuedBillManagerSame)},on:{"change":function($event){var $$a=_vm.companyManagerInfo.issuedBillManagerSame,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.companyManagerInfo, "issuedBillManagerSame", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.companyManagerInfo, "issuedBillManagerSame", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.companyManagerInfo, "issuedBillManagerSame", $$c)}}}}),_c('label',{staticClass:"label"},[_vm._v("세금계산서 담당자가 담당자 정보와 동일 ")])]),_vm._m(0)]),_c('div',{staticClass:"group row col2 gap40 align-start"},[_c('div',{staticClass:"group-inp",class:[
						_vm.companyManagerInfoValidClass.issuedBillName == 'error' ||
						_vm.companyManagerInfoValidClass.issuedBillName == 'empty'
							? 'invalid'
							: _vm.companyManagerInfoValidClass.issuedBillName == 'valid'
							? 'valid'
							: '',
					]},[_c('label',{staticClass:"label asterisk"},[_vm._v("세금계산서 담당자명")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.issuedBillName),expression:"companyManagerInfo.issuedBillName"}],attrs:{"type":"text","id":"issuedBillName","placeholder":"세금계산서 담당자를 입력해주세요","disabled":_vm.companyManagerInfo.issuedBillManagerSame},domProps:{"value":(_vm.companyManagerInfo.issuedBillName)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "issuedBillName", $event.target.value)},function($event){[
								_vm.checkInputLength($event, 2, 64)
									? (_vm.companyManagerInfoValidClass.issuedBillName = 'valid')
									: (_vm.companyManagerInfoValidClass.issuedBillName = 'error'),
							]}]}}),(_vm.companyManagerInfoValidClass.issuedBillName == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 세금계산서 담당자명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.issuedBillName == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 세금계산서 담당자명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"group-inp",class:[
						_vm.companyManagerInfoValidClass.issuedBillEmail == 'error' ||
						_vm.companyManagerInfoValidClass.issuedBillEmail == 'empty'
							? 'invalid'
							: _vm.companyManagerInfoValidClass.issuedBillEmail == 'valid'
							? 'valid'
							: '',
					]},[_c('label',{staticClass:"label asterisk"},[_vm._v("세금계산서 담당자 이메일")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.issuedBillEmail),expression:"companyManagerInfo.issuedBillEmail"}],attrs:{"type":"text","id":"issuedBillEmail","placeholder":"세금계산서 담당자 이메일을 입력해주세요","disabled":_vm.companyManagerInfo.issuedBillManagerSame},domProps:{"value":(_vm.companyManagerInfo.issuedBillEmail)},on:{"keyup":function($event){[
								_vm.checkEmail(_vm.companyManagerInfo.issuedBillEmail)
									? (_vm.companyManagerInfoValidClass.issuedBillEmail = 'valid')
									: (_vm.companyManagerInfoValidClass.issuedBillEmail = 'error'),
							]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "issuedBillEmail", $event.target.value)}}}),(_vm.companyManagerInfoValidClass.issuedBillEmail == 'error')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 세금계산서 담당자 이메일 형식이 올바르지 않습니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.issuedBillEmail == 'empty')?_c('p',{staticClass:"text-detail invalid"},[_c('i',{staticClass:"ic-16 ic-invalid"}),_vm._v(" 세금계산서 담당자 이메일은 필수 입력 항목입니다. ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{},[_c('i',{staticClass:"ic-16 ic-info"},[_c('span',{staticClass:"tooltip-layer left"},[_vm._v(" 세금계산서 담당자 이메일로 세금계산서 관련 메일이 발송됩니다. ")])])])
}]

export { render, staticRenderFns }