<template>
	<Fragment>
		<div class="container">
			<div class="body-contents fill-out">
				<h2 class="heading-1-b">신규 주문</h2>
				<ol class="stepper type2">
					<li class="step on">
						<a href="#" title="step01">
							<i>1</i>
							<span>서비스 및 상품 선택</span>
						</a>
					</li>
					<li class="step">
						<a href="#" title="step02">
							<i>2</i>
							<span>약관 동의 및 결제</span>
						</a>
					</li>
					<li class="step">
						<a href="#" title="step03">
							<i>3</i>
							<span>주문 완료</span>
						</a>
					</li>
				</ol>
				<div class="banner notice mb-20">
					<i class="ic-24 ic-note"></i>
					<p>서비스 신청 전 회사 정보와 개설정보를 확인해주세요!</p>
				</div>
				<div class="section-wrap">
					<div class="section-main">
						<div class="section-wrap gap64 mb-64">
							<!-- 개설정보 -->
							<company-site-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-site-info>
							<!-- 사업자 등록증 -->
							<company-basic-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-basic-info>
							<!-- 담당자 정보 -->
							<company-manager-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-manager-info>
						</div>
						<step-footer :stepBtnInfo="stepBtnInfo" :actionBeforeMove="gatherAllData"></step-footer>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import StepFooter from '@/components/common/StepFooter.vue';
import CompanyBasicInfo from '@/components/apply/serviceApplyInfo/CompanyBasicInfo.vue';
import CompanyManagerInfo from '@/components/apply/serviceApplyInfo/CompanyManagerInfo.vue';
import CompanySiteInfo from '@/components/apply/serviceApplyInfo/CompanySiteInfo.vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';

export default {
	components: {
		CompanyBasicInfo,
		CompanyManagerInfo,
		CompanySiteInfo,
		StepFooter,
	},
	data() {
		return {
			toastOpen: false,
			toastText: '',
			gatherFlag: false,
			saveFlag: false,
			stepBtnInfo: {
				prevLink: '/apply/step1',
				nextLink: '/apply/service/step2',
			},
			gatheredValid: {
				companyInfoValidClass: null,
				companyManagerInfoValidClass: null,
				companySiteInfoValidClass: null,
				confirm: null,
			},
			gatheredData: {
				companyInfo: null,
				companyManagerInfo: null,
				companySiteInfo: null,
			},
			validationCheckCnt: 0,
			firstCheck: {
				component: 3,
				element: null,
			},
		};
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$router.push('/apply/service/step2');
			}
		},
	},
	methods: {
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
			);
		},
		validate() {
			return true;
		},
		async saveAllInfo() {
			await this.$store.commit('SET_ALL_COMPANY_INFO', this.gatheredData);
			this.saveFlag = true;
		},
		gatherAllData() {
			this.gatherFlag = true;
		},
		async resetGatherFlag(data) {
			this.gatherFlag = false;
		},
		async gatherData(data) {
			if (data.type == 'companyInfo') {
				this.gatheredData.companyInfo = data.companyInfo;
				this.gatheredValid.companyInfoValidClass = data.companyInfoValidClass;
				if (!data.companyInfo.confirm) {
					this.openToast('사업자 진위확인은 필수 입니다.');
					this.gatheredValid.confirm = null;
					this.resetGatherFlag();
				} else {
					this.gatheredValid.confirm = true;
				}
			} else if (data.type == 'companyManagerInfo') {
				this.gatheredData.companyManagerInfo = data.companyManagerInfo;
				this.gatheredValid.companyManagerInfoValidClass = data.companyManagerInfoValidClass;
			} else if (data.type == 'companySiteInfo') {
				this.gatheredData.companySiteInfo = data.companySiteInfo;
				this.gatheredValid.companySiteInfoValidClass = data.companySiteInfoValidClass;
			}

			let promise = this.checkValid(this.gatheredValid);
			if (this.validationCheckCnt == 3 && this.firstCheck.element != null) {
				this.validationCheckCnt = 0;
				this.firstCheck.element.focus();
				this.resetFirstCheck();
			}
			return promise;
		},
		resetFirstCheck() {
			this.firstCheck = {
				component: 3,
				element: null,
			};
		},
		async checkValid(validData) {
			this.validationCheckCnt++;
			// 회사정보
			this.gatherFlag = false;
			for (const [name, data] of Object.entries(validData)) {
				if (name == 'companySiteInfoValidClass') {
					let componentNum = 2;
					if (data.basicItem.siteName != 'valid') {
						if (this.firstCheck.component > componentNum) {
							this.firstCheck.component = componentNum;
							this.firstCheck.element = document.getElementById('siteName');
						}
						return false;
					} else if (data.basicItem.masterId != 'valid') {
						if (this.firstCheck.component > componentNum) {
							this.firstCheck.component = componentNum;
							this.firstCheck.element = document.getElementById('masterId');
						}
						return false;
					} else if (data.basicItem.domain != 'valid') {
						if (this.firstCheck.component > componentNum) {
							this.firstCheck.component = componentNum;
							this.firstCheck.element = document.getElementById('domain');
						}
						return false;
					} else if (data.basicItem.url != 'valid') {
						if (this.firstCheck.component > componentNum) {
							this.firstCheck.component = componentNum;
							this.firstCheck.element = document.getElementById('url');
						}
						return false;
					}

					//멀티컴퍼니 옵션 주문 있을경우 확인
					if (this.$utils.isOrderMultiCompany() && data.basicItem.doClusterId != 'valid') {
						if (this.firstCheck.component > componentNum) {
							this.firstCheck.component = componentNum;
							this.firstCheck.element = document.getElementById('doClusterId');
						}
						return false;
					}

					for (let i in data.addonItem) {
						let val = data.addonItem[i];
						if (val.masterId != 'valid') {
							return false;
						}
					}
				} else {
					let componentNum = 0;
					if (name === 'companyManagerInfoValidClass') componentNum = 1;
					if (name === 'companyInfoValidClass') componentNum = 0;

					for (const [key, value] of Object.entries(data)) {
						if (value != 'valid') {
							if (this.firstCheck.component > componentNum) {
								this.firstCheck.component = componentNum;
								this.firstCheck.element = document.getElementById(key);
							}
							return false;
						}
					}
				}
			}
			await this.saveAllInfo();
			return true;
		},
	},
};
</script>

<style scoped>
/* @import '../../assets/css/order.css'; */
/* companyManagerInfo {
	margin-top: 10px !important;
} */
.tooltip-layer left {
	z-index: 100;
}
</style>
