<template>
	<div>
		<label v-if="useLabel" class="label mb-8">
			{{ attachLabelText }}
		</label>
		<div>
			<div class="group row align-middle">
				<div class="button file-attach" @click="openFileTypeInput" v-if="isEdit">
					<label>{{ attachBtnName }}</label>
					<input type="file" ref="fileInput" :id="id || inputId" v-on:change="selectFile($event)" />
				</div>
				<span v-if="isEdit">
					<i class="ic-16 ic-info">
						<span class="tooltip-layer left">
							세금계산서 발급을 원하시거나 경리회계서비스 이용을 원하실 경우 반드시 사업자 등록증을 첨부해주시기
							바랍니다.
						</span>
					</i>
				</span>
			</div>
			<p class="text-file-name" v-for="attach in attachList" :key="attach.attachKey">
				{{ attach.originFilename }}
				<button class="icon" title="삭제" v-on:click="deleteFile(attach.attachKey)" v-if="isEdit">
					<i class="ic-16 ic-close"></i>
				</button>
			</p>
		</div>
	</div>
</template>

<script>
import mixinsAlert from '@/components/common/mixinsAlert';
import { attach } from '@/components/common/mixinsAttach';
export default {
	mixins: [mixinsAlert, attach],
	props: {
		id: {
			type: String,
			default: 'uploadButton',
		},
		attachLabelText: {
			type: String,
			default: '첨부파일',
		},
		attachBtnName: {
			type: String,
			default: '사업자 등록증',
		},
		value: {
			type: Array,
			default: () => [],
			description: 'attaches',
		},
		useLabel: {
			type: Boolean,
			default: false,
		},
		validation: {
			type: Object,
			default() {
				return {};
			},
		},
		multiSelect: {
			type: Boolean,
			default: false,
		},
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			attaches: this.value,
			inputId: this._uid + 'domsupload',
		};
	},
	mounted() {
		this.attaches = this.attaches.filter((attach) => attach.id != '');
		const origin = this.$store.getters.getBusinessRegistration;
		if (origin) {
			this.attaches = origin;
		}
	},
	watch: {
		attaches() {
			this.$emit('input', this.attaches);
		},
		gatherFlag() {
			if (this.gatherFlag) {
				this.sendValidInfo();
			}
		},
	},
	computed: {
		attachList() {
			if (!this.attaches) return;
			return this.attaches.filter((attach) => attach.deletedAt == undefined || attach.deletedAt == null);
		},
	},
	methods: {
		setAttaches(fileInfo) {
			if (!this.multiSelect) {
				this.attaches = [fileInfo];
			} else {
				this.attaches.push(fileInfo);
			}
			if (this.attaches.length > 0) {
				this.$store.commit('SET_BUSINESS_REGISTRATION', this.attaches);
			}
		},
		deleteFile(fileId) {
			this.attaches = this.attaches.filter((attach) => {
				attach.attachKey !== fileId;
			});
			this.$store.commit('SET_BUSINESS_REGISTRATION', []);
		},
		validate(file) {
			// name check
			if (this.validation.name != undefined && !file?.name.includes(this.validation.name)) {
				this.alert({
					title: '파일 명 에러',
					contents: `잘못된 파일명 입니다. "${this.validation.name}" 를 포함시켜 주세요.`,
				});
				return false;
			}
			// 필요하면 용량, 확장자 등등
			return true;
		},
		sendValidInfo() {
			if (this.attaches.length > 0) {
				this.$store.commit('SET_BUSINESS_REGISTRATION', this.attaches);
			}
		},
	},
};
</script>

<style scoped>
.file_upload_help {
	right: initial !important;
	top: 3px !important;
}
</style>
